import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
import { CancelOutlined } from "@mui/icons-material";
import { container } from "../../views/SocialDevelopment.styles";
import {
  bgWhite,
  chipStyles,
  danger,
  font17,
  mt35,
  pt0,
} from "../sharedStyles";
import ErasableTextField from "../ErasableTextField";
import AddressFields from "../AddressFields";
import schoolsService from "../../service/schoolsService";
import AutoSave from "../AutoSave";
import lookupService from "../../service/lookupService";
import Authorize from "../Authorize";
import { PermissionsContext } from "../../context/PermissionsContext";
import APP_PERMISSIONS from "../../utils/constants/permissions";
import PERMISSION_TYPES from "../../utils/constants/permission_types";
import { QuickBarContext } from "../../context/QuickBarContext";

export default function Profile() {
  const [schoolId] = useOutletContext();
  const methods = useForm({ mode: "onChange" });
  const [school, setSchool] = useState(null);
  const [isSameAddress, setIsSameAddress] = useState(undefined);
  const [ethnicities, setEthnicities] = useState([]);
  const [schoolEthnicities, setSchoolEthnicities] = useState([]);
  const quickBarContext = useContext(QuickBarContext);
  const { hasAnyPermissionType, hasPermission } =
    useContext(PermissionsContext);

  const hasManagePermission = hasPermission(
    APP_PERMISSIONS.SCHOOL_PROFILE,
    PERMISSION_TYPES.MANAGE
  );

  const getSameAddressValue = (sch) => {
    if (sch === null) return true;
    return sch.mailing_address_id === sch.physical_address_id;
  };

  const getIsClosingValue = (sch) => {
    if (sch === null) return false;
    return sch.is_closing;
  };

  const getIsCampus = (sch) => {
    if (sch === null) return false;
    return sch.is_partner_campus;
  };

  const [showClosingReason, setShowClosingReason] = useState(
    getIsClosingValue(school)
  );

  const [showPartnerCampus, setShowPartnerCampus] = useState(
    getIsCampus(school)
  );

  const requiredMessage = "This field is required.";

  const renderError = (message) => (
    <Typography sx={danger}>{message}</Typography>
  );

  const renderErrorMessage = (label) => {
    if (methods.formState.errors?.[label]) {
      return renderError(methods.formState.errors?.[label].message);
    }
    return "";
  };

  const getSchool = async () => {
    if (schoolId) {
      const response = await schoolsService.fetchSchoolProfile(schoolId);

      if (response.data.mailing_address) {
        response.data.mailing_address.state = {
          country_id: response.data.mailing_address.country_id,
        };
      }

      if (response.data.physical_address) {
        response.data.physical_address.state = {
          country_id: response.data.physical_address.country_id,
        };
      }

      setShowClosingReason(getIsClosingValue(response.data));
      setShowPartnerCampus(getIsCampus(response.data));
      setIsSameAddress(getSameAddressValue(response.data));
      setSchoolEthnicities(response.data.school_ethnicities);
      setSchool(response.data);
    }
  };

  const getEthnicities = async () => {
    const response = await lookupService.fetchEthnicities();
    setEthnicities(response.data.data);
  };

  useEffect(() => {
    (async () => {
      getEthnicities();
      await getSchool();
    })();
  }, []);

  const getDefaultValue = (defaultValue, value) => {
    if (value) {
      return value;
    }

    return defaultValue;
  };

  const booleanMenuOptions = () => {
    const booleanSelect = [
      {
        label: "Yes",
        value: true,
      },
      { label: "No", value: false },
    ];

    return booleanSelect.map((menuItem) => (
      <MenuItem key={menuItem.value} value={menuItem.value}>
        {menuItem.label}
      </MenuItem>
    ));
  };

  const getNameOfSelectedLookup = (id) =>
    ethnicities.find((item) => item.id === id)?.name;

  const downloadSTSData = () => {
    schoolsService
      .downloadSTSData({ school_id: schoolId })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.filename);
        document.body.appendChild(link);
        link.click();
        quickBarContext.cleanStsExport();
      })
      .catch(() => {
        quickBarContext.cleanStsExport();
      });
  };

  useEffect(() => {
    if (quickBarContext.stsExport) {
      downloadSTSData();
    }
  }, [quickBarContext.stsExport]);

  return (
    <Authorize
      permission={hasAnyPermissionType(APP_PERMISSIONS.SCHOOL_PROFILE)}
    >
      {school && (
        <Box sx={container}>
          <FormProvider {...methods}>
            <form>
              <Grid sx={mt35} container columnSpacing={2}>
                <Grid
                  container
                  item
                  sm={10}
                  rowSpacing={3}
                  columnSpacing={2}
                  sx={{ borderRight: "1px solid black", pr: 6 }}
                >
                  <Grid item sm={4}>
                    <ErasableTextField
                      id="org_id"
                      label="Org ID"
                      fullWidth
                      isRequired
                      hasError={methods.formState.errors.org_id}
                      defaultValue={getDefaultValue("", school?.org_id)}
                      disabled
                    />
                    {renderErrorMessage("org_id")}
                  </Grid>
                  <Grid item sm={8} />

                  <Grid item sm={9}>
                    <ErasableTextField
                      id="name"
                      label="School Name"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.name}
                      defaultValue={getDefaultValue("", school?.name)}
                      isRequired
                      disabled
                    />
                    {renderErrorMessage("name")}
                  </Grid>
                  <Grid item sm={3}>
                    <ErasableTextField
                      id="year_opened"
                      label="Year Opened"
                      fullWidth
                      type="tel"
                      hasError={methods.formState.errors?.year_opened}
                      defaultValue={getDefaultValue("", school?.year_opened)}
                      maxLength={4}
                      disabled={!hasManagePermission}
                    />
                    {renderErrorMessage("year_opened")}
                  </Grid>

                  <Grid item sm={9}>
                    <ErasableTextField
                      id="administrative_email"
                      label="Administrative Email"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.administrative_email}
                      defaultValue={getDefaultValue(
                        "",
                        school?.administrative_email
                      )}
                      isRequired
                      disabled={!hasManagePermission}
                    />
                    {renderErrorMessage("administrative_email")}
                  </Grid>
                  <Grid item sm={3} />

                  <Grid item sm={9}>
                    <ErasableTextField
                      id="website"
                      label="Website"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.website}
                      defaultValue={getDefaultValue("", school?.website)}
                      isRequired
                      disabled={!hasManagePermission}
                    />
                    {renderErrorMessage("website")}
                  </Grid>
                  <Grid item sm={3} />

                  <Grid item sm={12}>
                    <Typography sx={{ fontSize: "24px", fontWeigth: "400" }}>
                      Mailing Address
                    </Typography>
                  </Grid>

                  <AddressFields
                    defaultCountryProp={1}
                    label="mailing_address"
                    requiredMessage={requiredMessage}
                    renderError={renderError}
                    entity={school}
                    disabled={!hasManagePermission}
                  />

                  <Grid item sm={12}>
                    <Typography sx={{ fontSize: "24px", fontWeigth: "400" }}>
                      Physical Address
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={isSameAddress}
                            {...methods.register("same_address", {
                              onChange: () => setIsSameAddress(!isSameAddress),
                            })}
                            disabled={!hasManagePermission}
                          />
                        }
                        label={
                          <Typography sx={font17}>
                            Same as mailing address
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  {!isSameAddress && (
                    <AddressFields
                      label="physical_address"
                      requiredMessage={requiredMessage}
                      renderError={renderError}
                      entity={school}
                      disabled={!hasManagePermission}
                    />
                  )}
                  <Grid item sm={12}>
                    <Typography sx={{ fontSize: "24px", fontWeigth: "400" }}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <ErasableTextField
                      multiline
                      lines={4}
                      id="description"
                      label="Description"
                      fullWidth
                      type="text"
                      hasError={methods.formState.errors?.description}
                      defaultValue={getDefaultValue("", school?.description)}
                      isMultiline
                      isRequired
                      maxLength={500}
                      disabled={!hasManagePermission}
                    />
                    {renderErrorMessage("description")}
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  sm={2}
                  rowSpacing={3}
                  columnSpacing={2}
                  sx={{ pl: "50px" }}
                >
                  <Grid item sm={12}>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeigth: "400",
                        paddingBottom: "20px",
                      }}
                    >
                      Student Levels
                    </Typography>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={school.has_ecp}
                            disabled
                            {...methods.register("has_ecp")}
                          />
                        }
                        label={<Typography sx={font17}>Has ECP</Typography>}
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={school.has_pk_tracker}
                            disabled
                          />
                        }
                        label={
                          <Typography sx={font17}>Has PK Students</Typography>
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={school.has_secondary_students}
                            disabled
                          />
                        }
                        label={
                          <Typography sx={font17}>
                            Has Secondary Students
                          </Typography>
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={school.has_elementary_courses}
                            disabled
                          />
                        }
                        label={
                          <Typography sx={font17}>
                            Has Elementary Courses
                          </Typography>
                        }
                      />
                    </FormGroup>

                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeigth: "400",
                        paddingBottom: "30px",
                        paddingTop: "20px",
                      }}
                    >
                      Options
                    </Typography>

                    <FormControl
                      fullWidth
                      error={methods.formState.errors?.termType && true}
                    >
                      <InputLabel sx={bgWhite} id="term_type_label" shrink>
                        Term Type*
                      </InputLabel>
                      <Select
                        labelId="term_type_label"
                        id="term_type_id"
                        defaultValue={getDefaultValue(1, school.term_type_id)}
                        disabled={!hasManagePermission}
                        {...methods.register("term_type_id", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      >
                        <MenuItem value="1">Semesters with Quarters</MenuItem>
                        <MenuItem value="2">Semesters Only</MenuItem>
                        <MenuItem value="3">Trimesters Only</MenuItem>
                        <MenuItem value="4">Quarters Only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item sm={12}>
                    <FormControl
                      fullWidth
                      error={methods.formState.errors?.termType && true}
                    >
                      <InputLabel sx={bgWhite} id="four_day_week_label" shrink>
                        Four day week*
                      </InputLabel>
                      <Select
                        labelId="four_day_week_label"
                        id="four_day_week"
                        defaultValue={getDefaultValue(
                          false,
                          school.four_day_week
                        )}
                        disabled={!hasManagePermission}
                        {...methods.register("four_day_week")}
                      >
                        {booleanMenuOptions()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl
                      fullWidth
                      error={methods.formState.errors?.termType && true}
                    >
                      <InputLabel sx={bgWhite} id="minutes_met_label" shrink>
                        School Minutes Met?*
                      </InputLabel>
                      <Select
                        labelId="minutes_met_label"
                        id="minutes_met"
                        disabled={!hasManagePermission}
                        defaultValue={getDefaultValue(
                          false,
                          school.minutes_met
                        )}
                        {...methods.register("minutes_met")}
                      >
                        {booleanMenuOptions()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={getDefaultValue(
                              false,
                              school.is_closing
                            )}
                            {...methods.register("is_closing", {
                              onChange: () =>
                                setShowClosingReason(!showClosingReason),
                            })}
                            disabled={!hasManagePermission}
                          />
                        }
                        label={
                          <Typography sx={font17}>School is closing</Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  {showClosingReason && (
                    <Grid item sm={12}>
                      <ErasableTextField
                        multiline
                        lines={5}
                        id="closing_reason"
                        label="Closing reason"
                        fullWidth
                        type="text"
                        hasError={methods.formState.errors?.closing_reason}
                        defaultValue={getDefaultValue(
                          "",
                          school?.closing_reason
                        )}
                        isRequired
                        isMultiline
                        disabled={!hasManagePermission}
                      />
                      {renderErrorMessage("closing_reason")}
                    </Grid>
                  )}
                  <Grid item sm={12} sx={pt0}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            defaultChecked={getDefaultValue(
                              false,
                              school.is_partner_campus
                            )}
                            disabled={!hasManagePermission}
                            {...methods.register("is_partner_campus", {
                              onChange: () =>
                                setShowPartnerCampus(!showPartnerCampus),
                            })}
                          />
                        }
                        label={
                          <Typography sx={font17}>
                            Is a partner campus
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  {showPartnerCampus && (
                    <Grid item sm={12}>
                      <ErasableTextField
                        id="partner_campus"
                        label="Partner Campus to (Org. ID.)"
                        fullWidth
                        type="text"
                        hasError={methods.formState.errors?.partner_campus}
                        defaultValue={getDefaultValue(
                          "",
                          school?.partner_campus
                        )}
                        isRequired
                        disabled={!hasManagePermission}
                      />
                      {renderErrorMessage("partner_campus")}
                    </Grid>
                  )}
                  <Grid item sm={12}>
                    <ErasableTextField
                      id="home_school_meetings"
                      label="Parent-Teacher Meetings"
                      fullWidth
                      type="number"
                      hasError={methods.formState.errors?.home_school_meetings}
                      defaultValue={getDefaultValue(
                        0,
                        school?.home_school_meetings
                      )}
                      isRequired
                      disabled={!hasManagePermission}
                    />
                    {renderErrorMessage("home_school_meetings")}
                  </Grid>
                  <Grid item sm={12}>
                    <FormControl fullWidth>
                      <InputLabel id="schoolEthnicitiesLabel">
                        School Ethnicities
                      </InputLabel>
                      <Select
                        labelId="schoolEthnicitiesLabel"
                        multiple
                        id="schoolEthnicities"
                        label="School Ethnicities"
                        value={schoolEthnicities}
                        disabled={!hasManagePermission}
                        {...methods.register("school_ethnicities", {
                          onChange: (event) => {
                            setSchoolEthnicities(event.target.value);
                            methods.setValue(
                              "school_ethnicities",
                              event.target.value,
                              {
                                shouldDirty: true,
                              }
                            );
                          },
                        })}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value, index) => (
                              <Chip
                                key={value}
                                sx={chipStyles}
                                label={getNameOfSelectedLookup(value)}
                                disabled={!hasManagePermission}
                                deleteIcon={
                                  <CancelOutlined
                                    sx={{ color: "black !important" }}
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                                onDelete={() => {
                                  const newValues = [...schoolEthnicities];
                                  newValues.splice(index, 1);
                                  setSchoolEthnicities(newValues);
                                  methods.setValue(
                                    "school_ethnicities",
                                    newValues,
                                    {
                                      shouldDirty: true,
                                    }
                                  );
                                }}
                              />
                            ))}
                          </Box>
                        )}
                      >
                        {ethnicities.map((ethnicity) => (
                          <MenuItem key={ethnicity.id} value={ethnicity.id}>
                            {ethnicity.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                  <Grid item sm={12} />
                </Grid>
              </Grid>
            </form>
            {methods.formState.isDirty &&
              Object.values(methods.formState.errors).length === 0 && (
                <AutoSave
                  saveMethod={schoolsService.updateSchool}
                  params={[schoolId]}
                  successMessage="Profile saved"
                  progressMessage="Saving profile..."
                />
              )}
          </FormProvider>
        </Box>
      )}
    </Authorize>
  );
}
